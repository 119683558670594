import React from "react";

import KitField from "./KitField";

const KitsFields = (props) => {
    const fields = props.fields.map((field) => {
        return(
            <KitField
                id={field.id}
                kitName={field.Name}
                onChange={props.onChange}
            />
        )
    });

    return( 
        <div className="form-kit-list">
            {fields}
        </div>
    );
}

export default KitsFields;
