import React from "react";

const KitField = ({ id, onChange, checked, kitName }) => {
    const handleChange = event => {
        onChange(id, event.target.checked);
    };

    return(
        <div className="form-kit-item">
            <input type="checkbox"
                id={id}
                name={id}
                checked={checked} 
                onChange={handleChange}
            />
            <label for={id}>{kitName}</label>
        </div>
    );
}

export default KitField;
