import React, { useState, useEffect }  from "react";
import { Helmet } from "react-helmet";

import KitsFields from "../components/inputs/KitsFields";
import Spinner from "../components/Spinner";
import Header from "../components/Header";
import Layout from "../layouts/master";

import { entriesService } from "../services/entries";
import { guidesService } from "../services/guides";
import { kitsService } from "../services/kits";

const IndexPage = () => {
    const [submitError, setSubmitError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(null);

    const [guide, setGuide] = useState(null);
    const [comments, setComments] = useState(null);
    const [inOut, setInOut] = useState(false);

    const [selectedKits, setSelectedKits] = useState({});
    const handleSelectedKits = (checkboxId, checked) => {
        setSelectedKits({...selectedKits, [checkboxId]: checked});
    };

    const [kits, setKits] = useState([]);
    const [guides, setGuides] = useState(null);

    async function getData() {
        const resGuides = await guidesService.guides();
        setGuides(resGuides.data);

        const resKits = await kitsService.kits();
        setKits(resKits.data);
    }

    useEffect(() => {
        getData();
    }, []);

    function doSubmit() {
        setSubmitError(null);
        setSubmitSuccess(null);
        let data = {
            "Guide": guide,
            "Comments": comments,
            "In": inOut !== "out",
            "Kits": selectedKits
        };
        let error = "";
        if(data.Guide == null) {
            error = "Please select a guide."
        }
        let kitsValid = [];
        Object.keys(data.Kits).forEach((kitId) => {
            if(data.Kits[kitId]) {
                kitsValid = [...kitsValid, kitId];
            }
        });
        if(kitsValid.length === 0) {
            error = "Please select at least one kit."
        }
        data = {...data, "Kits": kitsValid};
        if(error !== "") {
            setSubmitError(error);
        } else {
            entriesService.create(data);
            kitsService.updateMany(data);
            setSubmitSuccess("Successfully logged.");
        }
    }

    if(!guides) {
        return(
            <Layout>
                <Helmet title="Kitter | New Entry" />
                <Header />
                <Spinner />
            </Layout>
        );
    }
    return(
        <Layout>
            <Helmet title="Kitter | New Entry" />
            <Header />
            <h1>New Entry</h1>

            <form>
                <div className="form-header">Details</div>
                <table>
                    <tr>
                        <td>Guide</td>
                        <td>
                            <select name="guide" value={guide} onChange={e => setGuide(e.target.value)} required >
                                <option value="" selected disabled hidden>Pick your name...</option>
                                {
                                    guides.map((guide) => {return(
                                        <option value={ guide.Name }>{ guide.Name }</option>
                                    )})
                                }
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td>In or Out</td>
                        <td>
                            <select name="inOut" value={inOut} onChange={e => setInOut(e.target.value)}>
                                <option value="in">In</option>
                                <option value="out">Out</option>
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td>Comments</td>
                        <td>
                            <textarea id="comments" name="comments" value={comments} onChange={e => setComments(e.target.value)} rows="5" cols="33" />
                        </td>
                    </tr>

                </table>
                <div>
                <div className="form-header">Kits</div>
                    <KitsFields onChange={handleSelectedKits} fields={kits} />
                </div>

                <button type="button" onClick={doSubmit}>Submit New Entry</button>

                {submitError ? <p style={{color: 'red'}}>{submitError}</p> : <></>}
                {submitSuccess ? <p style={{color: 'green'}}>{submitSuccess}</p> : <></>}
            </form>
        </Layout>
    );
};

export default IndexPage;
