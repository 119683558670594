import axios from "axios";

async function guides() {
    const response = await axios.get(process.env.GATSBY_CMS_HOST + "/guides?_sort=Name:ASC")
    .then(response => {
        return response;
    }).catch(error => {
        return false;
    })

    return response;
}

export const guidesService = {
    guides
};